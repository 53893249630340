<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-4">Saldo</h1>
      <b-card class="mb-4">
        <div class="fs-2" style="margin-bottom:-10px;">&euro; 0,00</div>
        <hr/>
        <b-button variant="primary" v-b-modal.topup>Saldo aanvullen</b-button>
      </b-card>
    </b-container>
    <b-modal id="topup" title="Saldo aanvullen" hide-header-close>
        <div class="mb-3">
          <label>Vul het bedrag in dat je wilt storten.</label>
          <b-form-input />
        </div>
        <template #modal-footer="{ close }">
          <b-button variant="outline-secondary" @click="close()">Annuleren</b-button>
          <b-button variant="primary" to="/manage/organisation/setup/bank">Doorgaan</b-button>
        </template>
    </b-modal>
  </div>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Saldo',
        },
        components: {
        },
        data() {
            return {
              events: []
            }
        },
        computed: {},
        methods: {},
        created() {}
    }
</script>